import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import Fieldset from '../../../../../../../../Fieldset/Fieldset';
import Input from '../../../../../../../../Input/Input';
import useScreen from '../hooks/useScreen';
import RadioGroup from '../../../../../../../../RadioGroup/RadioGroup';
import Button from '../../../../../../../../Button/Button';
import useScript from '../../../../../../../hooks/useScript';

const Screen = ({ form, screenIndex, wizard, onSubmitForm }) => {

  const recaptchaApiKey = process.env.REACT_APP_RECAPTCHA_API_KEY;
  useScript(`https://www.google.com/recaptcha/enterprise.js?render="${recaptchaApiKey}"`, "grecaptcha");
  const tosAccept = form?.watch('tosAccept');

  const isInvalid = useMemo(
    () => (
      !tosAccept
    ),
    [
      tosAccept
    ],
  );

  useScreen({ isInvalid, screenIndex, wizard });

  return (
    <>
      <Fieldset legend="Disclaimers">
        <Row>
          <Col xs={12} md={12}>
            <p>Please take reasonable and prudent measures to preserve, protect and secure property from further damage. Take photographs of all damaged property and gather any and all receipts/invoices for lost, stolen, or damaged property in connection with this claim.</p>
          </Col>
          <Col xs={12} md={12}>
            <Input
              error={form?.formState.errors.tosAccept}
              autoComplete="off"
              id="tosAccept"
              label="Do you understand and accept these conditions?"
              type="checkbox"
              touched={form?.formState.touchedFields.tosAccept}
              {...form?.register('tosAccept')}
            />
          </Col>
        </Row>


      </Fieldset>
      <Button
        type="submit"
        style={{ width: '100%' }}
        className="g-recaptcha"
        data-sitekey={recaptchaApiKey}
        data-size="invisible"
        onClick={() => onSubmitForm()}
      >
        Submit
      </Button>

    </>
  );
};

export default Screen;
