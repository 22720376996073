import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import Fieldset from '../../../../../../../../Fieldset/Fieldset';
import Input from '../../../../../../../../Input/Input';
import useScreen from '../hooks/useScreen';
import RadioGroup from '../../../../../../../../RadioGroup/RadioGroup';
import { max as maxLength } from './schema';
import Textarea from '../../../../../../../../Textarea/Textarea';
import Select from '../../../../../../../../Select/Select';
import usStates from '../../../us_states.json';

const Screen = ({ form, screenIndex, wizard }) => {

  const isAnotherVehicleInvolved = form?.watch('isAnotherVehicleInvolved');
  const isOtherVehicleDamaged = form?.watch('isOtherVehicleDamaged');
  const isOtherVehicleDrivable = form?.watch('isOtherVehicleDrivable');
  const isOtherVehicleBusinessAddress = form?.watch('isOtherVehicleBusinessAddress');
  const otherVehicleLocationZipValidation = form?.formState.errors.otherVehicleLocationZip;
  const otherVehicleVehicleVinValidation = form?.formState.errors.otherVehicleVehicleVin;
  const otherVehicleDamageDescription = form?.watch('otherVehicleDamageDescription');
  const otherVehicleInitialPointOfImpactValue = form?.watch('otherVehicleInitialPointOfImpact');

  const isInvalid = useMemo(
    () => {
      if (!isAnotherVehicleInvolved) return true;
      if (isAnotherVehicleInvolved === "Yes") {
        if (!isOtherVehicleDamaged || !otherVehicleInitialPointOfImpactValue || !isOtherVehicleDrivable) return true;
        if (isOtherVehicleDrivable === 'No' && otherVehicleLocationZipValidation) return true;
        if (otherVehicleVehicleVinValidation) return true;
        if (isOtherVehicleDamaged === 'Yes' && !otherVehicleDamageDescription) return true;
      }
      return false;
    }
    ,
    [
      isAnotherVehicleInvolved,
      isOtherVehicleDamaged,
      isOtherVehicleDrivable,
      otherVehicleLocationZipValidation,
      otherVehicleVehicleVinValidation,
      otherVehicleDamageDescription,
      otherVehicleInitialPointOfImpactValue

    ],
  );
  useScreen({ isInvalid, screenIndex, wizard });

  return (
    <Fieldset legend="Other Vehicle Information">
      <Row>
        <Col xs={12} sm={12}>
          <RadioGroup
            id="isAnotherVehicleInvolved"
            form={form}
            label="Is there another Vehicle Involved?"
            touched={form?.formState.touchedFields.isAnotherVehicleInvolved}
            error={form?.formState.errors?.isAnotherVehicleInvolved}
            name="isAnotherVehicleInvolved"
          >
            <span value="Yes">Yes</span>
            <span value="No">No</span>
          </RadioGroup>
        </Col>
        {isAnotherVehicleInvolved === "Yes" &&
          (
            <>
              <Col xs={12} sm={6}>
                <Input
                  autoComplete="off"
                  id="otherVehicleYear"
                  label="Vehicle year"
                  {...form?.register('otherVehicleYear')}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Input
                  autoComplete="off"
                  id="otherVehicleMake"
                  label="Vehicle make"
                  {...form?.register('otherVehicleMake')}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Input
                  autoComplete="off"
                  id="otherVehicleModel"
                  label="Vehicle model"
                  {...form?.register('otherVehicleModel')}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Input
                  autoComplete="off"
                  id="otherVehicleVehicleColor"
                  label="Vehicle color"
                  {...form?.register('otherVehicleVehicleColor')}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Input
                  autoComplete="off"
                  id="otherVehicleLicensePlateNumber"
                  label="License plate number"
                  {...form?.register('otherVehicleLicensePlateNumber')}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Input
                  autoComplete="off"
                  id="otherVehicleVehicleVin"
                  label="Vehicle VIN Number"
                  touched={form?.formState.touchedFields.otherVehicleVehicleVin}
                  error={form?.formState.errors?.otherVehicleVehicleVin}
                  maxLength={17}
                  {...form?.register('otherVehicleVehicleVin', {
                    onChange: () => form?.trigger("otherVehicleVehicleVin"),
                  })}
                />
              </Col>
              <Col xs={12} sm={6}>
                <RadioGroup
                  form={form}
                  label="Is vehicle damaged?"
                  name="isOtherVehicleDamaged"
                  id="isOtherVehicleDamaged"
                >
                  <span value="Yes">Yes</span>
                  <span value="No">No</span>
                  <span value="Unknown">Unknown</span>
                </RadioGroup>
              </Col>
              {isOtherVehicleDamaged === "Yes" &&
                (
                  <Col xs={12} sm={6}>
                    <Textarea
                      id="otherVehicleDamageDescription"
                      label="Vehicle Damage"
                      maxLength={maxLength}
                      name="otherVehicleDamageDescription"
                      rows={3}
                      form={form}
                      error={form?.formState.errors.otherVehicleDamageDescription}
                      touched={form?.formState.touchedFields.otherVehicleDamageDescription}
                      {...form?.register('otherVehicleDamageDescription', {
                        onChange: () => form?.trigger('otherVehicleDamageDescription'),
                        required: 'This field is required.',
                      })}
                    />
                  </Col>

                )}
              <Row>
                <Col xs={12} sm={6}>
                  <Select
                    helperText="Please use the adjacent image to assist with this answer."
                    id="otherVehicleInitialPointOfImpact"
                    name="otherVehicleInitialPointOfImpact"
                    label="Initial Point of Impact"
                    touched={form?.formState.touchedFields.otherVehicleInitialPointOfImpact}
                    error={form?.formState.errors.otherVehicleInitialPointOfImpact}
                    {...form?.register('otherVehicleInitialPointOfImpact', {
                      onBlur: () => form?.trigger('otherVehicleInitialPointOfImpact'),
                      required: 'This field is required.',
                    })}
                  >
                    <option value={0}>N/A</option>
                    <option value={1}>1. Front Right</option>
                    <option value={2}>2. Front Center</option>
                    <option value={3}>3. Front Left</option>
                    <option value={4}>4. Front Right Quarter Panel</option>
                    <option value={5}>5. Hood</option>
                    <option value={6}>6. Front Left Quarter Panel</option>
                    <option value={7}>7. Right Side</option>
                    <option value={8}>8. Roof</option>
                    <option value={9}>9. Left Side</option>
                    <option value={10}>10. Rear Right Quarter Panel</option>
                    <option value={11}>11. Deck Lid/Trunk</option>
                    <option value={12}>12. Rear Left Quarter Panel</option>
                    <option value={13}>13. Rear Right</option>
                    <option value={14}>14. Rear</option>
                    <option value={15}>15. Rear Left</option>
                  </Select>
                </Col>
                <Col xs={12} sm={6}>
                  <img
                    src="/CarWithNumbers.png"
                    style={{ width: '100%' }}
                  />
                </Col>
              </Row>
              <Col xs={12} sm={6}>
                <Textarea
                  id="otherVehicleUnrelatedPriorDamage"
                  label="Unrelated Prior Damage"
                  maxLength={maxLength}
                  name="otherVehicleUnrelatedPriorDamage"
                  rows={3}
                  form={form}
                  {...form?.register('otherVehicleUnrelatedPriorDamage')}
                />
              </Col>
              <Col xs={12} sm={6}>
                <RadioGroup
                  form={form}
                  label="Is the Vehicle Drivable?"
                  name="isOtherVehicleDrivable"
                  id="isOtherVehicleDrivable"
                >
                  <span value="Yes">Yes</span>
                  <span value="No">No</span>
                  <span value="Unknown">Unknown</span>
                </RadioGroup>
              </Col>
              {isOtherVehicleDrivable === "No" &&
                (
                  <Fieldset legend="Where is the vehicle Located?">
                    <Input
                      autoComplete="off"
                      id="otherVehicleLocationStreetAddress"
                      label="Street address"
                      {...form?.register('otherVehicleLocationStreetAddress')}
                    />
                    <Row>
                      <Col xs={12} sm={4}>
                        <Input
                          autoComplete="off"
                          id="otherVehicleLocationCity"
                          label="City"
                          {...form?.register('otherVehicleLocationCity')}
                        />
                      </Col>
                      <Col xs={12} sm={4}>
                        <Select
                          autoComplete="off"
                          id="otherVehicleLocationState"
                          label="State"
                          {...form?.register('otherVehicleLocationState')}
                        >
                          <option disabled value="" />
                          {usStates.map((state) => (
                            <option
                              key={state}
                              value={state}
                            >
                              {state}
                            </option>
                          ))}
                        </Select>
                      </Col>
                      <Col xs={12} sm={4}>
                        <Input
                          autoComplete="off"
                          id="otherVehicleLocationZip"
                          label="Zip Code"
                          maxLength={10}
                          touched={form?.formState.touchedFields.otherVehicleLocationZip}
                          error={form?.formState.errors?.otherVehicleLocationZip}
                          {...form?.register('otherVehicleLocationZip', {
                            onChange: () => form?.trigger("otherVehicleLocationZip"),
                          })}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={4}>
                        <RadioGroup
                          form={form}
                          label="Is This a Business?"
                          name="isOtherVehicleBusinessAddress"
                          id="isOtherVehicleBusinessAddress"
                        >
                          <span value="Yes">Yes</span>
                          <span value="No">No</span>
                        </RadioGroup>
                      </Col>
                      {isOtherVehicleBusinessAddress === "Yes" &&
                        (
                          <>
                            <Col xs={12} sm={4}>
                              <Input
                                autoComplete="off"
                                id="OtherVehicleBusinessName"
                                label="Business Name"
                                {...form?.register('OtherVehicleBusinessName')}
                              />
                            </Col>
                            <Col xs={12} sm={4}>
                              <Input
                                autoComplete="off"
                                id="OtherVehicleBusinessPhone"
                                type="tel"
                                label="Business Phone"
                                {...form?.register('OtherVehicleBusinessPhone')}
                              />
                            </Col>
                          </>
                        )}
                    </Row>
                  </Fieldset>
                )}
              <Col xs={12} sm={6}>
                <Input
                  autoComplete="off"
                  id="OtherVehicleInsuranceCompanyName"
                  label="Insurance Company Name"
                  {...form?.register('OtherVehicleInsuranceCompanyName')}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Input
                  autoComplete="off"
                  id="otherVehicleInsuranceCompanyPolicyNumber"
                  label="Insurance Company Policy Number"
                  {...form?.register('otherVehicleInsuranceCompanyPolicyNumber')}
                />
              </Col>
            </>
          )}
      </Row>
    </Fieldset>
  );
};

export default Screen;
