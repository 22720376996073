import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import Fieldset from '../../../../../../../../Fieldset/Fieldset';
import Input from '../../../../../../../../Input/Input';
import useScreen from '../hooks/useScreen';
import RadioGroup from '../../../../../../../../RadioGroup/RadioGroup';
import Button from '../../../../../../../../Button/Button';
import useScript from '../../../../../../../hooks/useScript';

const Screen = ({ form, screenIndex, wizard, onSubmitForm }) => {
  const recaptchaApiKey = process.env.REACT_APP_RECAPTCHA_API_KEY;
  useScript(`https://www.google.com/recaptcha/enterprise.js?render="${recaptchaApiKey}"`, "grecaptcha");
  const imagePermission = form?.watch('imagePermission');
  const tosAccept = form?.watch('tosAccept');
  const emailValidation = form?.formState.errors.reporterAltEmail;
  const phoneValidation = form?.formState.errors.reporterAltPhone;
  const phonereporterAltEmail = form?.watch('reporterAltEmail');
  const phonereporterAltPhone = form?.watch('reporterAltPhone');


  const isInvalid =
    useMemo(
      () => {
        if (!imagePermission || !tosAccept) return true;
        if (imagePermission === "Text" && (phoneValidation || !phonereporterAltPhone)) return true;
        if (imagePermission === "Email" && (emailValidation || !phonereporterAltEmail)) return true;
        return false;
      },
      [
        imagePermission,
        emailValidation,
        phoneValidation,
        phonereporterAltPhone,
        phonereporterAltEmail,
        tosAccept,
      ],
    );

  useScreen({ isInvalid, screenIndex, wizard });

  return (
    <>
      <Fieldset legend="Disclaimers">
        <Row>
          <Col xs={12} sm={12}>
            <p>If applicable, we may want to send you an email or text message with a link which provides instruction to take photographs of your damaged vehicle.

            </p>
          </Col>
          <Col xs={12} md={12}>
            <RadioGroup
              error={form?.formState.errors.imagePermission}
              form={form}
              label="Do you give your permission?"
              name="imagePermission"
              onChange={() => form?.trigger('imagePermission')}
              touched={form?.formState.touchedFields.imagePermission}

            >
              <span value="No">No</span>
              <span value="Text">Yes, send me a text message</span>
              <span value="Email">Yes, send me an email</span>
            </RadioGroup>
          </Col>
          {imagePermission === "Text" && (<Col>
            <Input
              error={form?.formState.errors.reporterAltPhone}
              id="reporterAltPhone"
              label="Phone Number"
              type="tel"
              touched={form?.formState.touchedFields.reporterAltPhone}
              {...form?.register('reporterAltPhone', {
                onChange: () => form?.trigger('reporterAltPhone'),
              })}
            />
          </Col>)}
          {imagePermission === "Email" && (
            <Col>
              <Input
                error={form?.formState.errors.reporterAltEmail}
                id="reporterAltEmail"
                label="Email Address"
                type="email"
                touched={form?.formState.touchedFields.reporterAltEmail}
                {...form?.register('reporterAltEmail', {
                  onChange: () => form?.trigger('reporterAltEmail'),
                })}
              />
            </Col>
          )}
          <Col xs={12} md={12}>
            <p>Law requires vehicle owners to mitigate their damages, whether the vehicle is disabled or not. This includes, but is not limited to, protecting the vehicle from further damage and to move the vehicle to a storage free location.
              If your vehicle, whether disabled or not, is at any facility incurring charges for any reason, please be advised that you have the duty to move your vehicle to a storage free location.
              We will not be responsible for, nor will we pay, any unreasonable towing or storage fees incurred, prior to, during or after our involvement due to your failure to mitigate your loss.</p>
          </Col>
          <Col xs={12} md={12}>
            <Input
              error={form?.formState.errors.tosAccept}
              autoComplete="off"
              id="tosAccept"
              label="Do you understand and accept these conditions?"
              type="checkbox"
              touched={form?.formState.touchedFields.tosAccept}
              {...form?.register('tosAccept')}
            />
          </Col>
        </Row>
      </Fieldset>
      <Button
        type="submit"
        disabled={isInvalid}
        style={{ width: '100%' }}
        className="g-recaptcha"
        data-sitekey={recaptchaApiKey}
        data-size="invisible"
        onClick={() => onSubmitForm()}
      >
        Submit
      </Button>

    </>
  );
};

export default Screen;
