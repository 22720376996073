import * as yup from 'yup';

const max = 1000;
const message = `Your vehicleDamageDescription may not be than ${max.toLocaleString()} characters.`;

const schema = {
  pedestriansInvolved: yup.string().default('').required('This field is required'),
  pedestriansFirstName: yup.string().default(''),
  pedestriansMiddleName: yup.string().default(''),
  pedestriansLastName: yup.string().default(''),
  pedestriansStreetAddress: yup.string().default(''),
  pedestriansCity: yup.string().default(''),
  pedestriansState: yup.string().default(''),
  pedestriansZip: yup.string().default('').matches(/^(?:\d{5}(?:[-\s]?\d{4})?|\s*)$/, 'Please enter a valid zip code.'),
  pedestriansHomePhone: yup.string().default('').matches(/^(\(?[0-9]{3}\)?[ .-]?[0-9]{3}[ .-]?[0-9]{4})?$/ , 'Please enter a valid phone number.'),
  pedestriansCellPhone: yup.string().default('').matches(/^(\(?[0-9]{3}\)?[ .-]?[0-9]{3}[ .-]?[0-9]{4})?$/ , 'Please enter a valid phone number.'),
  pedestriansWorkPhone: yup.string().default('').matches(/^(\(?[0-9]{3}\)?[ .-]?[0-9]{3}[ .-]?[0-9]{4})?$/ , 'Please enter a valid phone number.'),
  pedestriansEmail: yup.string().default('').email('Please enter a valid email address.'),
  pedestrianDateOfBirth: yup.string().default('').test('dateOfBirth', 'Please enter a valid date of birth.', (value) => {
    const date = new Date(value);
    const minYear = 1900;
    const maxYear = new Date().getFullYear();
    const year = date.getFullYear();
    if (year < minYear || year > maxYear) return false;
    return !isNaN(date.getTime()) || value === '';
  }),
  pedestriansLicenseNumber: yup.string().default(''),
  pedestriansLicenseState: yup.string().default(''),
  pedestriansInjured: yup.string().default(''),
  pedestriansInjuryDescription: yup.string().default('').max(max, message),
  pedestrianDidSeekMedical: yup.string().default(''),
};

export default schema;
export { max };