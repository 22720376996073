import * as yup from 'yup';
const max = 1000;
const message = `Your injury description may not be longer than ${max.toLocaleString()} characters.`;

const schema = {
  numberOfPassengers: yup.string().default('').required('This field is required.'),
  passengers: yup.array().of(
    yup.object().shape({
      firstName: yup.string(),
      middleName: yup.string(),
      lastName: yup.string(),
      streetAddress: yup.string(),
      city: yup.string(),
      state: yup.string(),
      zip: yup.string().default('').matches(/^(?:\d{5}(?:[-\s]?\d{4})?|\s*)$/, 'Please enter a valid zip code.'),
      homePhone: yup.string().matches(/^(\(?[0-9]{3}\)?[ .-]?[0-9]{3}[ .-]?[0-9]{4})?$/, 'Please enter a valid phone number.'),
      dateOfBirth: yup.string().test('dateOfBirth', 'Please enter a valid date of birth.', (value) => {
        const date = new Date(value);
        const minYear = 1900;
        const maxYear = new Date().getFullYear();
        const year = date.getFullYear();
        if (year < minYear || year > maxYear) return false;
        return !isNaN(date.getTime()) || value === '';
      }),
      wasPassengerInjured: yup.string(),
      passengerInjuryDescription: yup.string().default('').max(max, message),
      didPassengerSeekMedical: yup.string(),
    }),
  ),
};

export default schema;
export { max };