import * as yup from 'yup';


const schema = {
  reporterAltEmail: yup.string().default('').email('Please enter a valid email address.').when('imagePermission', {
    is: 'Email',
    then: yup.string().default('').required('This field is required.'),
    otherwise: yup.string().default('')
  }),
  reporterAltPhone: yup.string().default('').matches(/^(\(?[0-9]{3}\)?[ .-]?[0-9]{3}[ .-]?[0-9]{4})?$/, 'Please enter a valid phone number.').when('imagePermission', {
    is: 'Text',
    then: yup.string().default('').required('This field is required.'),
    otherwise: yup.string().default('')
  }),
  imagePermission: yup.string().default('').nullable().required('This field is required.'),
  tosAccept: yup.boolean().oneOf([true], 'This field must be checked')
};

export default schema;