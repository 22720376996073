
const toEmail = process.env.REACT_APP_MAIL_IMAGERIGHT_TO_SEND;
const toAgentEmail = process.env.REACT_APP_MAIL_TO_AGENT;
const fromEmail = process.env.REACT_APP_MAIL_FROM;

const getTableAgentForm = (data) => {
    return `
    <p>Hello Brandy,</p>
    <p>You have a new agent application for Stonegate Insurance:</p>
    <table style="border-collapse: collapse; width: 100%; height: 129.5px;" border="1">
        <colgroup>
            <col style="width: 49.9387%;">
            <col style="width: 49.9387%;">
        </colgroup>
        <tbody>
            <tr style="height: 18.5px;">
                <td style="height: 18.5px;">Agency Name:</td>
                <td style="height: 18.5px;">${data.agencyName}</td>
            </tr>
            <tr style="height: 18.5px;">
                <td style="height: 18.5px;">Primary Agent Contact Name:</td>
                <td style="height: 18.5px;">${data.name}</td>
            </tr>
            <tr style="height: 18.5px;">
                <td style="height: 18.5px;">Phone Number</td>
                <td style="height: 18.5px;">${data.phone}</td>
            </tr>
            <tr style="height: 18.5px;">
                <td style="height: 18.5px;">Email:</td>
                <td style="height: 18.5px;">${data.email}</td>
            </tr>
            <tr style="height: 18.5px;">
                <td style="height: 18.5px;">Lines of Business</td>
                <td style="height: 18.5px;">${data.linesBusiness}</td>
            </tr>
            <tr style="height: 18.5px;">
                <td style="height: 18.5px;">Total Annual Premium</td>
                <td style="height: 18.5px;">${data.annualPremium}</td>
            </tr>
            <tr style="height: 18.5px;">
                <td style="height: 18.5px;">States They Operate In:</td>
                <td style="height: 18.5px;">${data.states}</td>
            </tr>
            <tr style="height: 18.5px;">
                <td style="height: 18.5px;">Physical Office Location Address:</td>
                <td style="height: 18.5px;">${data.physicalAddress}</td>
            </tr>
            <tr style="height: 18.5px;">
                <td style="height: 18.5px;">Website:</td>
                <td style="height: 18.5px;">${data.website}</td>
            </tr>
            <tr style="height: 18.5px;">
                <td style="height: 18.5px;">Office/Landline Phone Number:</td>
                <td style="height: 18.5px;">${data.officePhone}</td>
            </tr>
        </tbody>
    </table>
`
}

const getContentToEmail = (data) => {
    if (data.form === 'agent') {
        return getTableAgentForm(data)
    }
}

const sendEmailTo = (data) => {
    if (data.claimNumber) {
        return data.reporterEmail
    } else if (data.form === 'contact') {
        return data.emailTo
    } else if (data.form === 'agent') {
        return toAgentEmail
    } else {
        return toEmail
    }
}

const getSubject = (data) => {
    return data.form == "contact" ?
        "Contact Form " + data.subject :
        data.form == "agent" ?
            "New Agent Application for Stonegate Insurance" :
            "New Personal Auto Claim"
}

const getEmailData = (data) => {
    return {
        from: fromEmail,
        to: sendEmailTo(data),
        subject: getSubject(data),
        html: getContentToEmail(data)
    }
}

export { getEmailData }