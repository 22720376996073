import { AxiosError } from 'axios';
import React, { useCallback, useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { generateManualClaimPayment, getPolicyPermissions, getPublicKey, redirectToPaytrace } from '../../../../../../utils/endpoints';
import Button from '../../../../../Button/Button';
import Input from '../../../../../Input/Input';
import Select from '../../../../../Select/Select';
import CreditCardForm from './CreditCardForm';

const PolicyForm = ({ className }) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formSubmittedMessage, setFormSubmittedMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [hasPayment, setHasPayment] = useState(false);
  const [policyNumber, setPolicyNumber] = useState('');
  const [hashPolicy, setHashPolicy] = useState(window.location.hash.split('#')[1]);
  const [isPolicy, setIsPolicy] = useState(hashPolicy ? true : false)
  const [policyKey, setPolicyKey] = useState('');
  const [paymentType, setPaymentType] = useState('')
  const [owePayment, setOwePayment] = useState(true)

  const paymentTypes = ["Policy Premium Payment", "Claim Installment Payment"];

  const { handleSubmit, ...form } = useForm({
    defaultValues: {
      policyNumber: '',
      claimNumber: '',
      amount: 0,
    },
  });

  useEffect(() => {
    if (hashPolicy) {
      onSubmit();
    }
  }, [])

  useEffect(() => {
    window.onSubmit = onSubmit;
  })

  useEffect(() => {
    setHashPolicy(form?.watch('policyNumber'))
  }, [form?.watch('policyNumber')])

  const handleOnChange = (e) => {
    setPaymentType(e.target.value)
    setHasPayment(false)
    e.target.value === paymentTypes[0] ? setIsPolicy(true) : setIsPolicy(false)
    setFormSubmittedMessage("")
  }

  const onSubmit = useCallback(
    async (data) => {
      if (hashPolicy) {
        var data = {};
        setPaymentType(paymentTypes[0])
        form?.setValue('policyNumber', hashPolicy);
        data.policyNumber = hashPolicy;
      }
      data.policyNumber = data.policyNumber.trim();

      if (isPolicy) {
        setIsLoading(true)
        await getPolicyPermissions(data.policyNumber)
          .then(response => {
            setFormSubmittedMessage(response?.data?.payment_message);
            response?.data?.payment_message.includes("You do not owe any payment at this time") ? setOwePayment(false) : setOwePayment(true)
            if (response?.data?.allow_payment) {
              setIsError(false)
              getPublicKey(data.policyNumber)
                .then(response => {
                  setPolicyNumber(data.policyNumber)
                  setPolicyKey(response.data.public_key);
                  setHasPayment(true);
                  setIsLoading(false)
                })
            } else {
              setFormSubmittedMessage(response?.data?.payment_message)
              setHasPayment(false);
              setIsError(true)
              setIsLoading(false)
            }
          }).catch((reason = AxiosError) => {
            setIsError(true)
            setFormSubmittedMessage(reason?.response?.data?.message ? reason?.response?.data?.message : "An error has occurred, please contact Unique Insurance +1-866-426-8842")
            setIsLoading(false)
          })
      } else {
        setIsLoading(true)
        const response = await generateManualClaimPayment(data)
        if (response?.response?.status === 302 || response?.response?.status === 200) {
          redirectToPaytrace(response?.response?.data)
        } else {
          setIsError(true)
          setFormSubmittedMessage(response?.response?.data.message)
          setIsLoading(false)
        }
      }
    },
    [isPolicy, hashPolicy],
  );

  return (
    <>
      <form
        className={className}
        onSubmit={handleSubmit(onSubmit)}
      >
        {!hasPayment &&
          <Select
            disabled={hashPolicy}
            autoComplete="off"
            id="paymentType"
            name="paymentType"
            label="Payment Type"
            value={paymentType}
            onChange={e => handleOnChange(e)}
          >
            <option disabled value="">Choose one</option>
            {paymentTypes.map((paymentType) => (
              <option
                key={paymentType}
                value={paymentType}
              > {paymentType}
              </option>
            ))}
          </Select>
        }
        {paymentType !== "" &&
          <>
            {!formSubmitted &&
              <Row>
                {formSubmittedMessage &&
                  <Col className={!isError ? "PolicyForm__success__message" : owePayment ? "PolicyForm__error__message" : "PolicyForm__message"}>
                    <h3>{formSubmittedMessage}</h3>
                  </Col>
                }
                <Col xs={12} md={12}>
                  <Input
                    error={isPolicy ? form.formState.errors.policyNumber : form.formState.errors.claimNumber}
                    id={isPolicy ? "policyNumber" : "claimNumber"}
                    label={isPolicy ? "Policy Number*" : "Claim Number*"}
                    touched={isPolicy ? form.formState.touchedFields.policyNumber : form.formState.touchedFields.claimNumber}
                    disabled={hasPayment}
                    value={form?.watch(isPolicy ? 'policyNumber' : 'claimNumber')}
                    {...form.register(isPolicy ? 'policyNumber' : 'claimNumber', {
                      required: `You must enter your ${isPolicy ? "policy" : "claim"} number.`,
                    })}
                  />
                </Col>
              </Row>
            }
            {!isPolicy &&
              <Row>
                <Col xs={12} md={12}>
                  <Input
                    error={form.formState.errors.amount}
                    id="amount"
                    label={"Amount*"}
                    type="numeric"
                    touched={form.formState.touchedFields.amount}
                    {...form.register('amount', {
                      validate: value => value > 0 || "Amount must be greater than 0",
                    })}
                  />
                </Col>
              </Row>
            }
            {!hasPayment && (<Button
              type="submit"
              style={{ width: '100%', marginTop: '1.5rem' }}
              disabled={isLoading}
            >
              {isLoading ? 'Loading...' : 'Continue'}
            </Button>
            )}
          </>
        }
      </form>
      {hasPayment && <CreditCardForm policyKey={policyKey} policyNumber={policyNumber} setFormSubmittedPolicy={setFormSubmitted} />}
    </>
  );
};

export default PolicyForm;
