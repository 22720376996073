import React, { useMemo } from 'react';
import clsx from 'clsx';
import Markdown from 'markdown-to-jsx';
import parse from 'html-react-parser';
import { get } from 'lodash';

const Tile = ({
  body,
  className,
  header,
  headerAs = 'h3',
  id,
  image,
  displayAsIcons,
  scrollLink,
}) => {
  const Header = headerAs;
  const [alternativeText, formats] = useMemo(() => [
    get(image, 'data.attributes.alternativeText'),
    get(image, 'data.attributes.formats'),
  ], [image]);
  function RenderTile() {
    return <div className="Tile">
      <Header className="Tile__header">
        {parse(header)}
      </Header>
      <Markdown
        className="Tile__body"
        options={{ forceBlock: true }}
      >
        {body}
      </Markdown>
      {!displayAsIcons ? (formats?.small?.url && (
        <div className="Tile__image__wrapper">
          <img
            alt={alternativeText}
            className="Tile__image"
            src={formats.small.url}
          />
        </div>
      )) : (image?.data?.attributes?.url && <div className="Tile__image__wrapper">
        <img
          alt={alternativeText}
          className="Tile__icon"
          src={image?.data?.attributes?.url}
        />
      </div>)}
    </div>
  }

  return (
    <div className={clsx('Tile__wrapper', className)}>
      {scrollLink ?
        scrollLink.includes('http') ?
          <a className="Tile__link" href={`${scrollLink}`}>{RenderTile()}</a> :
          <a className="Tile__link" href={`#${scrollLink}`}>{RenderTile()}</a>
        : <>{RenderTile()}</>
      }
    </div>
  );
};

export default Tile;
