import React, { useEffect } from 'react';
// import * as yup from 'yup';
import ordinal from 'ordinal';
import { Col, Row } from 'react-bootstrap';
import { range } from 'lodash';
import { useFieldArray } from 'react-hook-form';
import Fieldset from '../../../../../../../../Fieldset/Fieldset';
import Input from '../../../../../../../../Input/Input';
import RadioGroup from '../../../../../../../../RadioGroup/RadioGroup';
import Select from '../../../../../../../../Select/Select';
import usStates from '../../../us_states.json';
import useScreen from '../hooks/useScreen';
import { max as maxLength } from './schema';
import Textarea from '../../../../../../../../Textarea/Textarea';

const Screen = ({ form, screenIndex, wizard }) => {
  const numberOfPassengersValue = form?.watch('numberOfClaimantPassengers');
  const passengersValue = form?.watch('passengersClaimant');
  const passengersErrors = form?.formState.errors.passengersClaimant;
  // const wasPassengerInjured = form?.watch('wasPassengerInjured');

  // intentionally not memoizing this variable.
  // doing so disables validation (unknown as to why).
  const isInvalid =
    !numberOfPassengersValue

  useScreen({ isInvalid, screenIndex, wizard });

  const { fields, append, remove } = useFieldArray({
    control: form?.control,
    name: 'passengersClaimant',
  });

  useEffect(() => {
    const newVal = parseInt(numberOfPassengersValue || 0);
    const oldVal = fields.length;

    if (newVal > oldVal) {
      for (let i = oldVal; i < newVal; i++) {
        append({
          firstName: '',
          middleName: '',
          lastName: '',
          streetAddress: '',
          city: '',
          state: '',
          zip: '',
          homePhone: '',
          cellPhone: '',
          workPhone: '',
          email: '',
        });
      }
      return;
    }

    for (let i = oldVal; i > newVal; i--) {
      remove(i - 1);
    }
  }, [fields, numberOfPassengersValue]);

  return (
    <>
      <RadioGroup
        error={form?.formState.errors.numberOfClaimantPassengers}
        form={form}
        id="numberOfClaimantPassengers"
        name="numberOfClaimantPassengers"
        label="How many Passengers in the other Vehicle? (other than the driver.)"
        
      >
        {range(5).map((i) => (
          <span key={i} value={i} >{i}</span>
        ))}
      </RadioGroup>
      {fields.map((field, i) => (
        <React.Fragment key={field.id}>
          <span aria-hidden="true" />
          <Fieldset legend={`${ordinal(i + 1)} Passenger&rsquo;s Information`}>
            <Row>
              <Col xs={12} sm={6} md={4}>
                <Input
                  autoComplete="off"
                  error={form?.formState.errors.passengersClaimant?.[i]?.firstName}
                  id={`firstName-${field.id}`}
                  label="First name"
                  touched={form?.formState.touchedFields.passengersClaimant?.[i]?.firstName}
                  {...form?.register(`passengersClaimant[${i}].firstName`, {
                    onBlur: () => form?.trigger('passengersClaimant'),
                  })}
                />
              </Col>
              <Col xs={12} sm={6} md={4}>
                <Input
                  autoComplete="off"
                  id={`middleName-${field.id}`}
                  label="Middle name"
                  {...form?.register(`passengersClaimant[${i}].middleName`)}
                />
              </Col>
              <Col xs={12} sm={6} md={4}>
                <Input
                  autoComplete="off"
                  error={form?.formState.errors.passengersClaimant?.[i]?.lastName}
                  id={`lastName-${field.id}`}
                  label="Last name"
                  touched={form?.formState.touchedFields.passengersClaimant?.[i]?.lastName}
                  {...form?.register(`passengersClaimant[${i}].lastName`, {
                    onBlur: () => form?.trigger('passengersClaimant'),
                  })}
                />
              </Col>
            </Row>
            <Input
              autoComplete="off"
              id={`streetAddress-${field.id}`}
              label="Street address"
              {...form?.register(`passengersClaimant[${i}].streetAddress`)}
            />
            <Row>
              <Col xs={12} sm={4}>
                <Input
                  autoComplete="off"
                  id={`city-${field.id}`}
                  label="City"
                  {...form?.register(`passengersClaimant[${i}].city`)}
                />
              </Col>
              <Col xs={12} sm={4}>
                <Select
                  autoComplete="off"
                  id={`state-${field.id}`}
                  label="State"
                  {...form?.register(`passengersClaimant[${i}].state`)}
                >
                  <option disabled value="" />
                  {usStates.map((state) => (
                    <option
                      key={state}
                      value={state}
                    >
                      {state}
                    </option>
                  ))}
                </Select>
              </Col>
              <Col xs={12} sm={4}>
                <Input
                  autoComplete="off"
                  id={`zip-${field.id}`}
                  label="Zip Code"
                  maxLength={10}
                  touched={form?.formState.touchedFields.passengersClaimant?.[i]?.zip}
                  error={form?.formState.errors.passengersClaimant?.[i]?.zip}
                  {...form?.register(`passengersClaimant[${i}].zip`, {
                    onChange: () => form?.trigger(`passengersClaimant[${i}].zip`),
                  })}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Input
                  id={`homePhone-${field.id}`}
                  label="Home phone number"
                  type="tel"
                  touched={form?.formState.touchedFields.passengersClaimant?.[i]?.homePhone}
                  error={form?.formState.errors.passengersClaimant?.[i]?.homePhone}
                  {...form?.register(`passengersClaimant[${i}].homePhone`, {
                    onChange: () => form?.trigger(`passengersClaimant[${i}].homePhone`),
                  })}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Input
                  autoComplete="off"
                  id={`dateOfBirth-${field.id}`}
                  label="Date of Birth"
                  max={new Date().toISOString().split('T')[0]}
                  min="1900-01-01"
                  touched={form?.formState.touchedFields.passengersClaimant?.[i]?.dateOfBirth}
                  error={form?.formState.errors.passengersClaimant?.[i]?.dateOfBirth}
                  type="date"
                  {...form?.register(`passengersClaimant[${i}].dateOfBirth`, {
                    onChange: () => form?.trigger(`passengersClaimant[${i}].dateOfBirth`)
                  })}
                />
              </Col>
              <Col xs={12} sm={6}>
                <RadioGroup

                  form={form}
                  id={`wasPassengerInjured-${field.id}`}
                  name={`wasPassengerInjured-${field.id}`}
                  label="Was this person injured?"
                // {...form?.register(`passengersClaimant[${i}].wasPassengerInjured`)}
                >
                  <span value="Yes">Yes</span>
                  <span value="No">No</span>
                </RadioGroup>
              </Col>
              <Col xs={12} sm={6}>
                <Textarea
                  id={`passengerInjuryDescription-${field.id}`}
                  label="Describe Injuries"
                  maxLength={maxLength}
                  name={`passengerInjuryDescription-${field.id}`}
                  rows={3}
                  form={form}
                  {...form?.register(`passengersClaimant[${i}].passengerInjuryDescription`)}
                />
              </Col>
              <Col xs={12} sm={6}>
                <RadioGroup
                  id={`didPassengerSeekMedical-${field.id}`}
                  form={form}
                  label="Did this person seek medical treatment?"
                  name={`didPassengerSeekMedical-${field.id}`}
                >
                  <span value="Yes">Yes</span>
                  <span value="No">No</span>
                  <span value="Unknown">Unknown</span>
                </RadioGroup>
              </Col>
            </Row>
          </Fieldset>
        </React.Fragment>
      ))}
    </>
  );
};

export default Screen;
