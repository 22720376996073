import * as yup from 'yup';

const schema = {
  policyHolderFirstName: yup.string().default('').required('This field is required'),
  policyHolderMiddleName: yup.string().default(''),
  policyHolderLastName: yup.string().default('').required('This field is required'),
  policyHolderStreetAddress: yup.string().default(''),
  policyHolderCity: yup.string().default(''),
  policyHolderState: yup.string().default(''),
  policyHolderZip: yup.string().default('').matches(/^(?:\d{5}(?:[-\s]?\d{4})?|\s*)$/, 'Please enter a valid zip code.'),
  policyHolderHomePhone: yup.string().default('').matches(/^(\(?[0-9]{3}\)?[ .-]?[0-9]{3}[ .-]?[0-9]{4})?$/, 'Please enter a valid phone number.'),
  policyHolderCellPhone: yup.string().default('').matches(/^(\(?[0-9]{3}\)?[ .-]?[0-9]{3}[ .-]?[0-9]{4})?$/, 'Please enter a valid phone number.'),
  policyHolderWorkPhone: yup.string().default('').matches(/^(\(?[0-9]{3}\)?[ .-]?[0-9]{3}[ .-]?[0-9]{4})?$/, 'Please enter a valid phone number.'),
  policyHolderEmail: yup.string().default('').email('Please enter a valid email address.'),
};

export default schema;
