import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import Fieldset from '../../../../../../../../Fieldset/Fieldset';
import Input from '../../../../../../../../Input/Input';
import RadioGroup from '../../../../../../../../RadioGroup/RadioGroup';
import Select from '../../../../../../../../Select/Select';
import Textarea from '../../../../../../../../Textarea/Textarea';
import usStates from '../../../us_states.json';
import useScreen from '../hooks/useScreen';
import { max as maxLength } from './schema';

const Screen = ({ form, screenIndex, wizard }) => {
  const damageTypeValue = form?.watch('damageType');
  const isVehicleDamagedValue = form?.watch('isVehicleDamaged');
  const isVehicleDriveable = form?.watch('isVehicleDrivable');
  const isBusinessAddress = form?.watch('isBusinessAddress');
  const vehicleDamageDescription = form?.watch('vehicleDamageDescription');
  const isVehicleDrivable = form?.watch('isVehicleDrivable');
  const vehicleLocationZipValidation = form?.formState.errors.vehicleLocationZip;
  const pointOfImpactValue = form?.watch('pointOfImpact');

  const isInvalid = useMemo(
    () => {
      if (!damageTypeValue || !isVehicleDamagedValue || !isVehicleDrivable || !pointOfImpactValue) return true;
      if (isVehicleDamagedValue === 'Yes' && !vehicleDamageDescription) return true;
      if (isVehicleDrivable === 'No' && vehicleLocationZipValidation) return true;
      return false;
    },
    [damageTypeValue, isVehicleDamagedValue, vehicleDamageDescription, isVehicleDrivable, vehicleLocationZipValidation, pointOfImpactValue],
  );

  useScreen({ isInvalid, screenIndex, wizard });

  return (
    <Fieldset legend="Unique&rsquo;s Insured Vehicle Information">
      <Row>
        <Col xs={12} sm={8}>
          <Select
            error={form?.formState.errors.damageType}
            id="damageType"
            label="Damage type"
            touched={form?.formState.touchedFields.damageType}
            {...form?.register('damageType', {
              onBlur: () => form?.trigger('damageType'),
              required: 'This field is required.',
            })}
          >
            <option disabled value="">Select the option that most applies</option>
            <option value="Collision with animal">Collision with animal</option>
            <option value="Theft">Theft</option>
            <option value="Fire">Fire</option>
            <option value="Vandalism">Vandalism</option>
            <option value="Weather/Natural Disasters">Weather/Natural Disasters</option>
            <option value="Falling objects">Falling objects</option>
            <option value="Glass Breakage">Glass Breakage</option>
            <option value="Collision Damage">Collision Damage</option>
          </Select>
        </Col>
      </Row>
      <span aria-hidden="true" />
      <RadioGroup
        error={form?.formState.errors.isVehicleDamaged}
        form={form}
        label="Is vehicle damaged?"
        name="isVehicleDamaged"
        touched={form?.formState.touchedFields.isVehicleDamaged}
      >
        <span value="Yes">Yes</span>
        <span value="No">No</span>
        <span value="Unknown">Unknown</span>
      </RadioGroup>
      {isVehicleDamagedValue === "Yes" && <Textarea
        id="vehicleDamageDescription"
        label="Describe the vehicle damage"
        maxLength={maxLength}
        name="vehicleDamageDescription"
        rows={3}
        form={form}
        error={form?.formState.errors.vehicleDamageDescription}
        touched={form?.formState.touchedFields.vehicleDamageDescription}
        {...form?.register('vehicleDamageDescription', {
          onChange: () => form?.trigger('vehicleDamageDescription'),
        })}
      />}
      <span aria-hidden="true" />
      <RadioGroup
        form={form}
        label="Is vehicle drivable?"
        name="isVehicleDrivable"
      >
        <span value="Yes">Yes</span>
        <span value="No">No</span>
        <span value="Unknown">Unknown</span>
      </RadioGroup>
      {isVehicleDriveable === "No" && <Fieldset legend="Where is the vehicle Located?">
        <Input
          autoComplete="off"
          id="vehicleLocationStreetAddress"
          label="Street address"
          {...form?.register('vehicleLocationStreetAddress')}
        />
        <Row>
          <Col xs={12} sm={4}>
            <Input
              autoComplete="off"
              id="vehicleLocationCity"
              label="City"
              {...form?.register('vehicleLocationCity')}
            />
          </Col>
          <Col xs={12} sm={4}>
            <Select
              autoComplete="off"
              id="vehicleLocationState"
              label="State"
              {...form?.register('vehicleLocationState')}
            >
              <option disabled value="" />
              {usStates.map((state) => (
                <option
                  key={state}
                  value={state}
                >
                  {state}
                </option>
              ))}
            </Select>
          </Col>
          <Col xs={12} sm={4}>
            <Input
              autoComplete="off"
              id="vehicleLocationZip"
              label="Zip Code"
              maxLength={10}
              touched={form?.formState.touchedFields.vehicleLocationZip}
              error={form?.formState.errors.vehicleLocationZip}
              {...form?.register('vehicleLocationZip', {
                onChange: () => form?.trigger('vehicleLocationZip'),
              })}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={4}>
            <RadioGroup
              form={form}
              label="Is This a Business?"
              name="isBusinessAddress"
            >
              <span value="Yes">Yes</span>
              <span value="No">No</span>
            </RadioGroup>
          </Col>
          {isBusinessAddress === "Yes" && <>
            <Col xs={12} sm={4}>
              <Input
                autoComplete="off"
                id="businessName"
                label="Business Name"
                {...form?.register('businessName')}
              />
            </Col>
            <Col xs={12} sm={4}>
              <Input
                autoComplete="off"
                id="businessPhone"
                type="tel"
                label="Business Phone"
                {...form?.register('businessPhone')}
              />
            </Col></>}

        </Row>

      </Fieldset>}

      <span aria-hidden="true" />
      <Row>
        <Col xs={12} sm={6}>
          <Select
            helperText="Please use the adjacent image to assist with this answer."
            id="pointOfImpact"
            label="Point of impact"
            touched={form?.formState.touchedFields.pointOfImpact}
            error={form?.formState.errors.pointOfImpact}
            {...form?.register('pointOfImpact', {
              onBlur: () => form?.trigger('pointOfImpact'),
              required: 'This field is required.',
            })}
          >
            <option disabled value="">Choose one</option>
            <option value={0}>N/A</option>
            <option value={1}>1. Front Right</option>
            <option value={2}>2. Front Center</option>
            <option value={3}>3. Front Left</option>
            <option value={4}>4. Front Right Quarter Panel</option>
            <option value={5}>5. Hood</option>
            <option value={6}>6. Front Left Quarter Panel</option>
            <option value={7}>7. Right Side</option>
            <option value={8}>8. Roof</option>
            <option value={9}>9. Left Side</option>
            <option value={10}>10. Rear Right Quarter Panel</option>
            <option value={11}>11. Deck Lid/Trunk</option>
            <option value={12}>12. Rear Left Quarter Panel</option>
            <option value={13}>13. Rear Right</option>
            <option value={14}>14. Rear</option>
            <option value={15}>15. Rear Left</option>
          </Select>
          <Textarea
            id="unrelatedPriorDamage"
            className="mt-4"
            label="Any unreleated prior damage?"
            maxLength={maxLength}
            name="unrelatedPriorDamage"
            rows={2}
            form={form}
            {...form?.register('unrelatedPriorDamage')}
          />
        </Col>
        <Col xs={12} sm={6}>
          <img
            src="/CarWithNumbers.png"
            style={{ width: '100%' }}
          />
        </Col>
      </Row>
    </Fieldset>
  );
};

export default Screen;
