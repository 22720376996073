import React, { useCallback, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Button from '../../../../../Button/Button';
import Input from '../../../../../Input/Input';
import Textarea from '../../../../../Textarea/Textarea';
import { AxiosError } from 'axios';
import { sendEmail } from '../../../../../../utils/endpoints';
import useScript from '../../../../hooks/useScript';
import Modal from '../../../../../Modal/Modal';
import { getEmailData } from '../../../../../../utils/email-templates';

const Form = ({ className }) => {
  const recaptchaApiKey = process.env.REACT_APP_RECAPTCHA_API_KEY;

  const { grecaptcha } = useScript(`https://www.google.com/recaptcha/enterprise.js?render="${recaptchaApiKey}"`, "grecaptcha");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formSubmittedMessage, setFormSubmittedMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { handleSubmit, ...form } = useForm({
    defaultValues: {
      email: '',
      name: '',
      phone: '',
      linesBusiness: '',
      annualPremium: '',
      agencyName: '',
      states: '',
      physicalAddress: '',
      website: '',
      officePhone: '',
    },
  });

  const onSubmit = useCallback(
    async (data) => {
      data.form = "agent"
      setIsLoading(true);
      grecaptcha.enterprise.ready(() => {
        grecaptcha.enterprise.execute({ action: 'submit' }).then(async function (token) {
          await sendEmail(getEmailData(data)).then(response => {
            setIsLoading(false);
            setFormSubmitted(true);
            setFormSubmittedMessage('Thank you for contact us we will be in touch with you soon!')
          }).catch((reason = AxiosError) => {
            setIsLoading(false);
            setFormSubmitted(true);
            setFormSubmittedMessage('There was an error submitting your form. Please refresh the page and try again.')
          });
        });
      });
    },
    [grecaptcha],
  );

  return (
    <>
      {!formSubmitted ? (<form
        className={className}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Row>
          <Col xs={12} md={6}>
            <Input
              error={form.formState.errors.agencyName}
              id="agencyName"
              label="Agency Name*"
              touched={form.formState.touchedFields.agencyName}
              {...form.register('agencyName', {
                required: 'You must enter your agency name.',
              })}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              error={form.formState.errors.name}
              id="name"
              label="Primary Agency Contact Name*"
              touched={form.formState.touchedFields.name}
              {...form.register('name', {
                required: 'You must enter your primary agency contact name.',
                pattern: {
                  value: /^[A-Za-z\s]+$/,
                  message: 'There cannot be numbers/special characters in name.',
                },
              })}
            />
          </Col>
        </Row>
        <Row>

          <Col xs={12} md={6}>
            <Input
              error={form.formState.errors.phone}
              id="phone"
              label="Phone Number*"
              touched={form.formState.touchedFields.phone}
              type="tel"
              {...form.register('phone', {
                required: 'You must enter your phone number.',
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'There cannot be letters/special characters in phone number.',
                },
              })}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              error={form.formState.errors.email}
              id="email"
              label="Email*"
              touched={form.formState.touchedFields.email}
              type="email"
              {...form.register('email', {
                required: 'You must enter your email.',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email address.',
                },
              })}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Input
              error={form.formState.errors.linesBusiness}
              id="linesBusiness"
              label="Lines of Business*"
              touched={form.formState.touchedFields.linesBusiness}
              type="linesBusiness"
              {...form.register('linesBusiness', {
                required: 'You must enter your lines of business.',
                pattern: {
                  value: /^[A-Za-z\s]+$/,
                  message: 'There cannot be numbers/special characters in line of business.',
                },
              })}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              error={form.formState.errors.annualPremium}
              id="annualPremium"
              label="Total Annual Premium*"
              touched={form.formState.touchedFields.annualPremium}
              type="annualPremium"
              {...form.register('annualPremium', {
                required: 'You must enter your total annual premium.',
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'There cannot be letters/special characters in Total Annual Premium.',
                },
              })}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            {(() => {
              const maxLength = 1000;

              return (
                <Textarea
                  error={form.formState.errors.states}
                  form={form}
                  id="states"
                  label="States that you operate in*"
                  placeholder="i.e. California, Illinois, Florida ..."
                  maxLength={maxLength}
                  name="states"
                  rows={3}
                  touched={form.formState.touchedFields.states}
                  {...form.register('states', {
                    maxLength: {
                      value: maxLength,
                      message: `Your states may not be more than ${maxLength.toLocaleString()} characters.`,
                    },
                    required: 'You must enter a state.',
                    pattern: {
                      value: /^[A-Za-z\s]+$/,
                      message: 'There cannot be numbers/special characters in state.',
                    },
                  })}
                />
              );
            })()}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Input
              error={form.formState.errors.physicalAddress}
              id="physicalAddress"
              label="Physical Office Location Address*"
              touched={form.formState.touchedFields.physicalAddress}
              type="physicalAddress"
              {...form.register('physicalAddress', {
                required: 'You must enter your physical office location address.',
              })}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Input
              error={form.formState.errors.website}
              id="website"
              label="Website*"
              touched={form.formState.touchedFields.website}
              type="website"
              {...form.register('website', {
                required: 'You must enter your website.',
                pattern: {
                  value: /^[a-zA-Z0-9]+([-.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,}(:[0-9]{1,5})?(\/.*)?$/,
                  message: 'Invalid website format.',
                },
              })}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              error={form.formState.errors.officePhone}
              id="officePhone"
              label="Office/Landline Phone Number*"
              touched={form.formState.touchedFields.officePhone}
              type="tel"
              {...form.register('officePhone', {
                required: 'You must enter your office/landline phone number.',
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'There cannot be letters/special characters in phone number.',
                },
              })}
            />
          </Col>
        </Row>
        <Button
          type="submit"
          style={{ width: '100%' }}
          className="g-recaptcha"
          data-sitekey={recaptchaApiKey}
          data-size="invisible"
          onClick={handleSubmit(onSubmit)}
        >
          Submit
        </Button>
        {isLoading &&
          <Modal
            isCloseButtonVisible={false}
            onClose={() => { }}
            open={true}
            width="max-content"
            panelClassName="loading__modal"
          >
            <Spinner animation="grow"> </Spinner>
            <p>Processing your request</p>
          </Modal>
        }
      </form>) : (<h3>{formSubmittedMessage}</h3>)}
    </>
  );
};

export default Form;
